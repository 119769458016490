/* Стили карточек */

.card {
  cursor: pointer;
  max-width: 270px;
  max-height: 230px;
}

.card__afisha {
  width: 100%;
  height: 100%;
  max-height: 151px;
  object-fit: cover;
  object-position: 50% 50%;
  margin-bottom: 10px;
}

.card__discription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
}

.card__title {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.card__like-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: rgba(49, 49, 49, 0.8);
  border: none;
  border-radius: 50px;
}

.card__like-btn:active > .card__like-heart {
  background: url(../../../images/card/like/like-active.svg) no-repeat;
}

.card__like-btn:active {
  background-color: rgba(49, 49, 49, 0.1);
}

.card__like-heart {
  width: 10px;
  height: 10px;
  background: url(../../../images/card/like/like-inactive.svg) no-repeat;
}

.card__like-heart-active {
  width: 10px;
  height: 10px;
  background: url(../../../images/card/like/like-active.svg) no-repeat;
}

.card__delete-btn {
  opacity: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: rgba(49, 49, 49, 1);
  border: none;
  border-radius: 50px;
}

.card__delete-cross {
  width: 100%;
  height: 90%;
  transform: rotateZ(45deg);
  color: rgba(255, 255, 255, 1);
}

.card:hover > .card__discription > .card__delete-btn {
  opacity: 1;
}

.card__delete-btn:focus {
  outline: none;
}

.card__delete-btn:hover {
  background-color: rgba(49, 49, 49, 0.8);
}

.card__delete-btn:active {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
}

.card__delete-btn:hover > span.card__delete-cross {
  color: rgba(255, 255, 255, 0.8);
}

.card__duration-film {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(139, 139, 139, 1);
}

@media (max-width: 768px) {
  .card {
    max-width: 339px;
    max-height: 269px;
  }

  .card__afisha {
    margin-bottom: 14px;
  }

  .card__discription {
    padding-bottom: 29px;
    margin-bottom: 6px;
  }
}

@media (max-width: 320px) {
  .card {
    max-width: 300px;
    max-height: 246px;
  }
}
