.portfolio__container {
  padding: 0 70px 125px;
}

.portfolio-links__name {
  margin: 0;
  padding-bottom: 50px;
  color: rgba(139, 139, 139, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.portfolio-links__items {
  padding: 0;
  margin: 0;
}

.portfolio-links__description {
  margin: 0;
  padding-top: 10px;
  border-top: rgba(226, 226, 226, 1) 1px solid;
  color: rgb(255, 64, 64);
}

.portfolio-links__item {
  display: flex;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 20px 0;
}

.portfolio-links__item:first-child {
  padding-top: 0;
}

.portfolio-links__item:last-child {
  border-bottom: none;
}

.portfolio-links__link {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
}

.portfolio-links__circle-inner {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.portfolio-links__circle {
  animation-name: circle-pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  clip-path: circle(50% at 50% 50%);
  background-color: rgb(255, 0, 0);
  height: 20px;
  width: 20px;
}

.portfolio-links__link-arrow {
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  line-height: 60px;
}

.portfolio-links__link:hover + .portfolio-links__link-arrow {
  color: rgba(255, 255, 255, 0.8);
}

.portfolio-links__link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.portfolio-links__link_disabled {
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
  cursor: default;
}

.portfolio-links__link-arrow_disabled {
  color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .portfolio__container {
    padding: 0 50px 90px;
  }

  .portfolio-links__link {
    font-size: 28px;
  }

  .portfolio-links__span {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .portfolio__container {
    padding: 0 14px 70px;
  }

  .portfolio-links__item {
    gap: 20px;
  }
}

@media (max-width: 320px) {
  .portfolio__title {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 28px;
    margin-bottom: 60px;
    gap: 40px;
  }

  .portfolio-links__name {
    font-size: 14px;
    padding-bottom: 40px;
  }

  .portfolio-links__link {
    font-size: 18px;
    line-height: 28px;
  }

  .portfolio-links__link-arrow {
    font-size: 18px;
    line-height: 28px;
  }
}

@keyframes circle-pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}