.not-found {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 246px 0 60px;
  max-width: 400px;
  height: 100%;
  width: 100%;
}

.not-found__title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.not-found__error {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
}

.not-found__text {
  line-height: 19px;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.button {
  color: rgba(66, 133, 244, 1);
  text-decoration: none;
}

@media (max-width: 768px) {
  .not-found__container {
    max-height: 394px;
    padding: 0;
  }
}

@media (max-width: 320px) {
  .not-found__container {
    padding: 329px 10px 30px;
    max-width: 300px;
  }

  .not-found__error {
    margin-bottom: 10px;
    font-size: 80px;
    line-height: 97px;
  }

  .not-found__text {
    font-size: 12px;
    line-height: 15px;
  }
}
