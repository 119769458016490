.form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form__container {
  max-width: 396px;
  max-height: 560px;
  width: 100%;
  height: 100%;
  padding: 70px 0;
}

.form__header {
  margin-bottom: 40px;
}

.form__logo-link {
  display: block;
  width: 38px;
  height: 38px;
  margin-bottom: 40px;
}

.form__logo {
  width: 100%;
  height: 100%;
}

.form__greeting {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.form__authorization {
  margin: 0;
  margin-top: 16px;
  color: rgba(139, 139, 139, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.form__authorization-link {
  color: rgba(66, 133, 244, 1);
  text-decoration: none;
}

.form__authorization-link:hover {
  color: rgba(66, 133, 244, 0.8);
}

@media (max-width: 768px) {
  .form__container {
    padding: 232px 0;
  }
}

@media (max-width: 430px) {
  .form__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
  }

  .form__logo {
    margin-bottom: 50px;
  }

  .form__container {
    max-width: 260px;
    max-height: 694px;
    margin: 0 auto;
    padding: 56px 30px 30px;
  }

  .form__content {
    max-height: 468px;
  }

  .form__authorization {
    font-size: 12px;
    line-height: 15px;
  }
}
