.footer__container {
  padding: 79px 70px 20px;
  font-size: 13px;
  line-height: 16px;
}

.footer__discription {
  color: rgba(139, 139, 139, 1);
  border-bottom: 1px solid rgba(66, 66, 66, 1);
  margin: 0;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.footer__info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.footer__discription-items {
  display: flex;
  padding: 0;
  list-style-type: none;
}

.footer__discription-item {
  padding-right: 20px;
}

.footer__discription-item:last-child {
  padding-right: 0;
}

.footer__discriptions_content_copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__discriptions-years {
  margin: 0;
}

.footer__discription-link {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.footer__discription-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .footer__container {
    padding: 79px 30px 20px;
  }
}

@media (max-width: 700px) {
  .footer__container {
    padding: 79px 10px 20px;
  }
}

@media (max-width: 320px) {
  .footer__container {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__discription {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__info {
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer__discriptions_content_copyright {
    color: rgba(139, 139, 139, 1);
  }

  .footer__discription-items {
    text-align: center;
    margin: 0 auto;
    flex-direction: column;
    padding: 30px 0;
  }

  .footer__discription-item {
    padding: 0 0 12px;
  }

  .footer__discription-item:last-child {
    padding: 0;
  }
}
