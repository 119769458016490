.register__form {
  max-height: 380px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.register__inputs {
  display: flex;
  flex-direction: column;
}

.register__label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-bottom: 10px;
  color: rgba(139, 139, 139, 1);
}

.register__input {
  height: 46px;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 0 15px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(47, 47, 47, 1);
}

.register__input:last-of-type {
  margin: 0;
}

.register__span {
  font-size: 10px;
  line-height: 12px;
  min-height: 25px;
  padding: 5px 0 5px;
  color: rgba(255, 0, 76, 1);
}

.register__span_type_register {
  text-align: center;
  min-height: 10px;
  max-height: 10px;
}

.span:last-of-type {
  margin: 0;
}

.register__form-btn-sends-register {
  border: none;
  width: 100%;
  height: 45px;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(248, 248, 248, 0.8);
  border-radius: 3px;
}

.register__form-btn-sends-register_active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(66, 133, 244, 1);
}

.register__form-btn-sends-register_active:hover {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(66, 133, 244, 0.8);
}
