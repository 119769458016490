.promo {
  background-color: rgba(92, 92, 92, 1);
  padding: 75px 70px;
}

.promo__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 84px;
}

.promo__logo {
  width: 100%;
  max-width: 310px;
}

.promo__info {
  margin: 0;
  max-width: 640px;
}

.promo__title > p.promo__title {
  padding-bottom: 0;
}

.promo__title {
  margin: 0;
  padding-bottom: 36px;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
}

.promo__discription {
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.promo__btn-detailed {
  border: 1px solid rgba(255, 255, 255, 1);
  width: 129px;
  height: 36px;
  border-radius: 3px;
}

.promo__btn-detailed:hover {
  opacity: 0.8;
}

.promo__btn-link {
  display: block;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@media (max-width: 768px) {
  .promo {
    padding: 140px 44px 76px;
  }

  .promo__container {
    max-width: 680px;
    flex-direction: column-reverse;
    padding-bottom: 92px;
  }

  .promo__logo {
    margin: 0 auto 48px;
  }

  .promo__title {
    padding-bottom: 22px;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  .promo__discription {
    margin: 0 auto;
    text-align: center;
    max-width: 260px;
  }

  .promo__btn-detailed {
    margin: 0 auto;
    border-radius: 3px;
  }
}

@media (max-width: 700px) {
  .promo {
    padding: 70px 10px 30px;
  }
}

@media (max-width: 320px) {
  .promo__logo {
    max-width: 210px;
  }

  .promo__container {
    padding-bottom: 80px;
    max-width: 300px;
    width: 100%;
  }

  .promo__title {
    font-size: 30px;
    line-height: 38px;
  }
}
