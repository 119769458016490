/* Стили контайнера карточек */
.cards {
  display: grid;
  justify-content: center;
  padding: 70px 70px 10px;
  grid-template-columns: repeat(auto-fill, min(270px));
  grid-template-rows: 230px;
  gap: 38px 20px;
}

.cards-next {
  padding: 50px 70px 80px;
}

.cards_block_hide {
  display: none;
}

.cards-next_block_hide {
  display: none;
}

.cards-next__btn-next {
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(47, 47, 47, 1);
}

.cards-next__btn-next:hover {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(47, 47, 47, 0.8);
}

.cards-next__btn-next:active {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(47, 47, 47, 0.8);
  box-shadow: 1px 1px 30px rgba(23, 23, 23, 1);
}

.cards-next__btn-next:focus {
  outline: 1.5px solid rgb(23, 23, 23, 1);
}

.nothing-found {
  display: flex;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  width: 100%;
  max-height: 230px;
  height: 100vh;
  border-radius: 10px;
  background: rgb(46, 46, 46);
}

.nothing-found__text_error {
  color: rgba(238, 52, 101, 1);
}

.nothing-found__text_error:nth-child(2) {
  margin: 10px 0;
}

.nothing-found_hide {
  display: none;
}

@media (max-width: 768px) {
  .cards {
    padding: 70px 30px 10px;
    grid-template-columns: repeat(2, 339px);
    grid-template-rows: 269px;
    gap: 30px 30px;
  }

  .cards-next {
    padding: 50px 30px 80px;
  }
}

@media (max-width: 740px) {
  .cards {
    grid-template-columns: repeat(1, auto);
  }

  .cards-next {
    padding: 50px 30px 80px;
  }
}

@media (max-width: 320px) {
  .cards {
    padding: 40px 10px 10px;
    grid-template-columns: repeat(1, 300px);
    grid-template-rows: repeat(auto, 246px);
    gap: 34px;
  }

  .cards-next {
    padding: 50px 10px 80px;
  }
}
