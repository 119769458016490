.authorization__form {
  max-height: 380px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.authorization__inputs {
  display: flex;
  flex-direction: column;
}

.authorization__label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-bottom: 10px;
  color: rgba(139, 139, 139, 1);
}

.authorization__input {
  height: 46px;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 0 15px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(47, 47, 47, 1);
}

.authorization__span {
  font-size: 10px;
  line-height: 12px;
  min-height: 30px;
  padding: 10px 0 10px 0;
  color: rgba(255, 0, 76, 1);
}

.authorization__span_type_login {
  padding: 0;
  min-height: 20px;
  text-align: center;
  font-size: 11px;
  margin: 0 auto;
}

.authorization__span:last-of-type {
  margin: 0;
}

.authorization__form-btn-sends-authorization {
  border: none;
  width: 100%;
  height: 45px;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(248, 248, 248, 0.8);
  border-radius: 3px;
}

.authorization__form-btn-sends-authorization_active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(66, 133, 244, 1);
}

.authorization__form-btn-sends-authorization_active:hover {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(66, 133, 244, 0.8);
}
