.searchform__container {
  padding: 80px 70px 10px;
}

.searchform__form__container {
  display: flex;
  border-bottom: 1px solid rgba(170, 170, 170, 0.2);
  padding-bottom: 84px;
}

.searchform__form-input__container {
  position: relative;
  display: flex;
  width: 100%;
  margin-right: 41px;
}

.searchform__form-input {
  padding: 0;
  padding-left: 22px;
  height: 48px;
  width: 100%;
  color: rgba(139, 139, 139, 1);
  border: 1.5px solid rgba(170, 170, 170, 0.2);
  background-color: rgba(32, 32, 32, 1);
  border-radius: 50px;
}

.searchform__error-inputSearch {
  opacity: 0;
  display: block;
  color: rgba(238, 52, 101, 1);
  margin-top: 10px;
  background-color: rgba(139, 139, 139, 0.1);
  border-radius: 6px;
  text-align: center;
  width: 100%;
  height: 20px;
}

.active {
  opacity: 1;
}

.searchform__form-input:focus {
  outline: 1.5px solid rgba(170, 170, 170, 0.3);
}

.searchform__form-btn {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7px;
  right: 7px;
  width: 80px;
  height: 34px;
  padding: 0;
  background-color: rgba(66, 133, 244, 1);
  border: none;
  border-radius: 48px;
}

.searchform__form-btn:hover {
  background-color: rgba(66, 133, 244, 0.8);
}

.searchform__form-btn:active {
  background-color: rgba(66, 133, 244, 0.6);
}

@media (max-width: 768px) {
  .searchform {
    padding-top: 74px;
  }

  .searchform__container {
    padding: 60px 30px 10px;
  }

  .searchform__form__container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 56px;
  }

  .searchform__form-input__container {
    padding-bottom: 37px;
    margin: 0;
  }

  .searchform__form-input:focus {
    outline: 1.5px solid rgba(170, 170, 170, 0.3);
  }
}

@media (max-width: 320px) {
  .searchform__container {
    padding: 60px 10px 10px;
  }

  .searchform__form__container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 44px;
  }

  .searchform__form-input__container {
    padding-bottom: 43px;
  }

  .searchform__form-input:focus {
    outline: 1.5px solid rgba(170, 170, 170, 0.3);
  }
}
